.container{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    justify-content: space-around;
}

.overallcontainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    height: 300px;
    width: 603px;
    left: 0px;
    top: 0px;
    border-radius: 4px;
    background: #FFFFFF;
    border: 1px solid #EFF4FA;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.bars-card-department{
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  float: right;
  height: 400px;
  width: 360px;
  left: 0px;
  top: 0px;
  border-radius: 4px;
  background: #FFFFFF;
  border: 1px solid #EFF4FA;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.overallchart1{
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: left;
  margin-bottom: 25px;
  height: 300px;
  width: 350px;
  left: 0px;
  top: 0px;
  border-radius: 4px;
  background: #FFFFFF;
  border: 1px solid #EFF4FA;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.overallchart2{
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: left;
  height: 400px;
  width: 600px;
  left: 0px;
  top: 0px;
  border-radius: 4px;
  background: #FFFFFF;
  border: 1px solid #EFF4FA;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.cardcontainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    height: 250px;
    width: 603px;
    left: 0px;
    top: 0px;
    border-radius: 4px;
    background: #FFFFFF;
}

.overalltitle{
    font-style: normal;
    font-weight: 580;
    font-size: 17px;
    line-height: 16px;
    color: #222B45;
    margin-left: 3%;
}

.dropdownmenuday {
  color: #2196F3;
  float: right;
}

.cardtitle{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.overall-card{
    display: flex;
    flex-direction:column;
    justify-content: space-evenly;
    width: 167px;
    height: 213.3px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 10px;
    margin-top: 20px;
    margin-left: 20px;
}

.charts{
    display: flex;
    margin-top: 50px;
    justify-content: space-around;
}

.number{
    margin-left: 0px;
    margin-top: 8px;
    font-size: 30px;
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: center;
    color: #2196F3;
}

.number-of{
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: center;
    align-items: center;
    letter-spacing: 0.4px;
    color: #222B45;
    height: 100px;
}

.bars-card{
    display: flex;
    justify-content: space-around;
    background: #FFFFFF;
    border: 1px solid #EFF4FA;
    width: 650px;
    height: 373px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

}

.lines-card{
    display: flex;
    background: #FFFFFF;
    border: 1px solid #EFF4FA;
    width: 300px;
    height: 425px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    height: 300px;
    width: 430px;
    border-radius: 4px;
}

.user-icon{
    background-image: url(./statistics-icons/user.png);
    background-repeat: no-repeat ;
    background-position: 7px ;
  }

.cel-icon{
    background-image: url(./statistics-icons/cel.png);
    background-repeat: no-repeat ;
    background-position: 17px ;
}

.msg-icon{
    background-image: url(./statistics-icons/msg.png);
    background-repeat: no-repeat ;
    background-position: 25px ;
}
