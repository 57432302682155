.JobInfoCard_Container{
    width: 350px;
    height: 90px;
    background: #FFFFFF;
    color: #fff;
    border-radius: 4px;
    padding: 5px 0;
    position: absolute;
    z-index: 1000;
    margin-left: -60px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
}
  
.JobInfoCard_Container::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 15%;
    margin-left: -5px;
    border-width: 20px;
    border-style: solid;
    border-color: transparent transparent rgba(241, 237, 237, 0.7) transparent;
}

.JobInfoCard_ContainerTop{
    width: 350px;
    height: 90px;
    background: #FFFFFF;
    color: #fff;
    border-radius: 4px;
    padding: 5px 0;
    position: absolute;
    z-index: 1000;
    margin-left: -60px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
    bottom: 50%;
}
  
.JobInfoCard_ContainerTop::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 15%;
    margin-left: -5px;
    border-width: 20px;
    border-style: solid;
    border-color: rgba(241, 237, 237, 0.7) transparent transparent  transparent;
}

.JobInfoCard_SecondRow{
    position: absolute;
    height: 26px;
    left: 16px;
    right: 202px;
    top: 25px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: #000000;
    width: 200px;
}

.JobInfoCard_Time{
    position: absolute;
    left: 4%;
    top: 55%;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px; 
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #000000;
    font-size: 12px;
}

.JobInfoCard_Progress{
    position: absolute;
    top: 25%;
    right: 10%;
    font-weight: bold;
    font-size: 20px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.2px;
    color: #000000;
}

.JobInfoCard_AssignToText{
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #000000;
    position: absolute;
    top: 52%;
    right: 10%;
}