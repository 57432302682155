.main-container {
  display: flex;
  grid-template-columns: minmax(200px, 25%) 1fr;
  height: 93.5vh;
  margin-top: 6.5vh;
  margin-left: 4.7vh;
}
#sidebar {
  display: inline;
  position:'absolute';
  width: 31%;
  max-height: 100%;
  margin-left: 50px;
  margin: 0;
  padding: 0 12px;
  background: linear-gradient(11.06deg,#697175 0.79%, #426779 100%);
  opacity: 0.93;
}
#mappedinContainer{
  height: 93.5vh;
  margin-top: 6.5vh;
  margin-left: 4.7vh;
  display: inline;
  /* background: linear-gradient(11.06deg, #282C30 0.79%, #17232C 100%);
  opacity: 0.93; */
  /* width: 70%;
  height: 100%; */
  /* z-index: -1; */
}
#mappedin-map {
  height: 93.5vh;
  margin-top: 6.5vh;
  margin-left: 4.7vh;
  /* margin-left: 32%;
  background-color: #17232C; */
}
#backDashboard{
  position: absolute;
  /* background-color:#5e6d7a; */
  background: linear-gradient(#466477  0.79%, #697174 100%) ;
  width: 40%;
  height: 7.6%;
  z-index: 2;
  left: 35%;
  border-radius: 0px 0px 10px 10px;
}
#campus{
  width: 55%;
  color: white;
  margin-left: 20%;
  font-size: 20px;
  padding-top:20px;
}
#backLbl{
  position: absolute;
  color:white;
  font-size:12px;
  margin-left:85%;
  top:17%;
  width:10%;
  float:right;
  cursor: pointer;
}
#dashboard-icon{
  display:'block';
  margin-left:30%;
}
#mappedin-app{
  margin-top: 6vh;
}
.ibVuKH{
  background: linear-gradient(#466477  0.79%, #697174 100%) !important;
}
/* .gerfdj{
  top:20%
} */
.kgYbXp{
  background: linear-gradient(#466477  0.79%, #697174 100%) !important;
}
.fwgNwA{
  border-radius: 8px 0px 0px 8px !important;
}
.sc-etwtAo{
  top: 6vh !important;
  left: 4.7vh !important;
}
.sc-LKuAh{
  top: 6vh !important;
}