.ButtonItem_Container{
    display: flex;
    width: 170px;
    margin-left: 25px;
    border-radius: 6px;
    height: 20px;
    padding: 15px;
}

.ButtonItem_ContainerSelected{
    display: flex;
    width: 170px;
    margin-left: 25px;
    border-radius: 6px;
    height: 20px;
    padding: 15px;
    background-color: #E3F2FD !important;
    color: #2196F3 !important;
}

.ButtonItem_Container:hover{
    background-color: #dfdbdbc0;
    cursor: pointer
}

.ButtonItem_Text{
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 3px;
    width: 110px;
}

.TabFilterDescription{
    color: rgb(0, 0, 0);
    display: block;
    font-size: 16px;
    line-height: 16px;
    position: relative;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    padding: 16px;
    margin-top: 12px;
    font-weight: bold;
}