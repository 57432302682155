
.jobsHistory-container{
    margin-top: 190px;
    width: 90%;
    margin-left: 100px;
}
.jobsHistory-TitleContainer{
    display: flex;
}
.jobsHistory-JobInfoViewTitleIcon{
    position: absolute;
    left: 100px;
    top: 110px;
}
.jobsHistory-Title{
    position: absolute;
    color: #2843A3;
    font-weight: 500;
    font-size: 26px;
    left: 140px;
    top: 105px;
}
.jobsHistory-Path1{
    margin-top: 5px;
    width: 158px;
    height: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #9E9E9E;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.jobsHistory-Path2{
    margin-top: 5px;
    width: 88px;
    height: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #9E9E9E;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 5px;
}
.jobsHistory-PathContainer{
    position: absolute;
    top: 150px;
    display: flex;
    left: 100px;
}