.UsersList_ItemDisabled{
    padding: 10px;
    color: #b9b9b9;
}
.UsersList_List{
    width: 80%;
    height: 95%;
    padding:5px;
    margin-left: 20px;
    overflow: auto;
}

.UsersList_List::-webkit-scrollbar { 
    display: none;
}

.UsersList_Item{
    padding: 10px
}

.UsersList_Item:hover{
    cursor: pointer;
    background-color: #F1F1F1;
}

.UsersList_Container{
    left:180px;
    width: 230px;
    height: 230px;
    background: #FFFFFF;
    color: black;
    border-radius: 4px;
    padding: 5px 0;
    position: absolute;
    z-index: 1000;
    margin-left: -60px;
    visibility: hidden;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
}