.ActionMessage_ActionStatusLabelContainer{
    color: #458E2D;
    display: flex;
    width: 150px;
    margin-right: 15px;
}

.ActionMessage_ActionStatusLabel{
    /* position: absolute;
    top: -12px;
    left: 20%; */
}

.ActionMessage_Icon{
    margin-top: 14px;
    padding-left: 10px;
}