.JobInfo_Container{
    left: 2%;
    top: 22%;
    width: 660px;
    height: 382px;
    background: #FFFFFF;
    color: #fff;
    border-radius: 4px;
    border: 1px solid #D6D6D6;
    padding: 5px 0;
    position: absolute;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.JobInfo_Title{
    position: absolute;
    height: 36px;
    left: 44px;
    right: 460px;
    top: 35px;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: #000000;
    width: 400px;
}

.JobInfo_AssignTo{
    width: 500px;
    position: absolute;
    left: 6.47%;
    right: 81.95%;
    top: 23.65%;
    bottom: 71.04%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #757575;
}

.JobInfo_CardTime{
    position: absolute;
    left: 6.47%;
    right: 32.44%;
    top: 30%;
    bottom: 52.44%;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
}

.JobInfo_CardTimeMonth{
    color: black;
    font-size: 13px;
    top: 37%;
    position: absolute;
    left: 15%;
}

.JobInfo_CardTimeDivider{
    position: absolute;
    left: 26%;
    right: 32.44%;
    top: 30%;
    bottom: 52.44%;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: black;
}

.JobInfo_CardEndTime{
    position: absolute;
    left: 30.46%;
    right: 32.44%;
    top: 30%;
    bottom: 52.44%;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
}

.JobInfo_CardTimeEndMonth{
    color: black;
    font-size: 13px;
    top: 37%;
    position: absolute;
    left: 39%;
}

.JobInfo_SpecimenTrackingBox{
    color: #838383;
    font-size: 12px;
    position: absolute;
    left: 6.47%;
    right: 63.69%;
    top: 46%;
    bottom: 86.12%;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    width: 150px;
}

.JobInfo_CardProgress{
    position: absolute;
    top: 43%;
    left: 75%;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.2px;
    color: #000000;
}

.JobInfo_CardProgressBar{
    top: 52%;
    position: absolute;
    width: 550px;
    left: 6.47%;
}

.JobInfo_ErrorDetailsContainer{
    position: absolute;
    top: 59%;
    left: 6%;
}

.JobInfo_ErrorDetailsTitle{
    color: black;
    font-weight: 600;
    font-size: 17px;
}

.JobInfo_ErrorDetailsDescription{
    color: black;
    font-size: 14px;
    margin-top: 10px;
}

.JobInfo_JobIdContainer{
    display: flex;
    position: absolute;
    top: 85%;
    text-align: right;
    width: 100%;
    float: right;
    margin-bottom: 10px;
}

.JobInfo_JobIdTitle{
    margin-left: 68%;
    color: #757575;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.JobInfo_JobIdDescription{
    margin-left: 5px;
    color: #2843A3;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
}