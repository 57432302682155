.RouteInfoCard_Container{
    position: absolute;
    top: 22%;
    left: 55%;
    width: 550px;
    height: 600px;
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.RouteInfoCard_Title{
    position: absolute;
    width: 350px;
    height: 24px;
    left: 10%;
    top: 5%;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #000000;
}

.RouteInfoCard_Timeline{
    position: absolute;
    top: 10%;
    left: 10%;
    width: 500px;
    height: 500px;
    overflow: auto;
}