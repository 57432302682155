.users-container{
    padding: 16px;
    padding-left: 70px;
    margin-bottom: 60px;
    margin-top:5%;
}

.add-button{
    position: relative;
    margin-left: 95%;
    bottom:15vh;
}

.roleLabel {
    /*line-height: 48px;*/
    display: table;
}
.roleLabel label {
    vertical-align: middle;
    display: table-cell;
}
.d-vector{
    position: absolute;
    opacity: 0.1;
    margin-top: 2px;
    width:100%;
    height:13%;
}