.autocomplete-box{
    display: flex;
    align-items: center;
    margin-top: 15px;    
}

.AutoComplete{
    width: 100%;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 2px;
    font-family: inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
}

.AutoComplete input{
    width: 100%;
    border: none;
    font-family: inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    padding: 10px 5px;
    box-sizing: border-box;
    outline: none;
    height: 40px;
}

.AutoComplete ul{
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0;
    border-top: 1px solid grey;
}

.AutoComplete ul::before {
    content:  "";
}

.AutoComplete li{
    padding: 10px 5px;
    cursor: pointer;
}

.AutoComplete li:hover{
    text-decoration: underline;
    background-color: rgba(128, 128, 128, 0.20);
}
