html, body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
}
#map {
  width: 100%;
  height: 100%;
}
#levelpicker {
  position: absolute;
  top: 60px;
  right: 10px;
  border-radius: 7px;
  padding: 5px 0;
  cursor:pointer;
  width: 60px;
}
#levelpicker button{
background-color:#808080;
color:#555555;
font-size: 20px;
text-align: center;
width: 37px;
height: 37px;
margin: 0px 10px 10px 10px;
border-radius: 50%;
border: 2px solid#696969; 
cursor:pointer;
}
#levelpicker button:focus {outline:0;}
#levelpicker li {
  display: block;
  list-style: none;
  /* padding: 5px 10px; */
  padding: 6% 15%;
  text-align: center;
  font-family: sans-serif;
  color: #c4c4c4;
  background: #808080;
  border-radius: 0px 0px 0px 0px;
 
}
.floorSelected{
border-radius: 7px 7px 7px 7px !important;
height: 20px;
}
.last-floor{
border-top: 10px solid  #808080;
border-radius: 7px 7px 0px 0px !important;
}
.first-floor {
border-radius: 0px 0px 7px 7px !important;
border-bottom: 7px solid  #808080;
}
#levelpicker li.selected {
  color: white;
  background: #696969;
}
.circle-annotation-title {
  position: absolute;
  width: auto;
  white-space: nowrap;
  color: #9a9393;
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
  transform: translateY(50%);
}
.exhibit-annotation {
  background-color: rgb(205, 141, 200);
}
.restaurant-annotation {
  background-color: rgb(247, 165, 101);
}
.shopping-annotation {
  background-color: rgb(60, 176, 150);
}
#map_canvas {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px
}
@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
.history-callout:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 68px;
  bottom: -21px;
  border: 8px solid transparent;
  border-top: 13px solid  rgb(193,193,193,.30);
  z-index: 3;
}
.equipment-callout:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 68px;
  bottom: -21px;
  border: 8px solid transparent;
  border-top: 13px solid  rgb(193,193,193,.30);
  z-index: 3;
}
.user-callout:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 75px;
  bottom: -21px;
  border: 8px solid transparent;
  border-top: 13px solid  rgb(193,193,193,.30);
}
.user-small-callout:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 9px;
  bottom: -21px;
  border: 8px solid transparent;
  border-top: 13px solid  rgb(193,193,193,.60);
}
.equipment-small-callout:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 9px;
  bottom: -21px;
  border: 8px solid transparent;
  border-top: 13px solid  rgb(193,193,193,.60);
}
.anchor-callout:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 58px;
  bottom: -21px;
  border: 8px solid transparent;
  border-top: 13px solid  white;
}
.anchor-error-callout:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 68px;
  bottom: -21px;
  border: 8px solid transparent;
  border-top: 13px solid  white;
}
.blurred {
  background: gray;
  filter: blur(20px);
  position: relative;
  top: -315px;
  z-index: -1;
}
.tr{
  width: 1px;
  height: 1px;
  top: 5px;
  left: 60px;
  border-radius: 30%;
  z-index: 1000;
}
.trace-16,.trace-15,.trace-14,.trace-13,.trace-12,.trace-11,.trace-10,.trace-9,
.trace-8,.trace-7,.trace-6,.trace-5,.trace-4,.trace-3,.trace-2,.trace-1,.trace-0,
.trace--1,.trace--2{
  background:#A9A9A9;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  z-index: 1000;    
}