
.JobHistory-container{
    margin-left: 25px;
}
.JobHistory-label{
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    margin-left: 30px;
}
.JobHistory-text{
    position: absolute;
    left: 240px;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
}
.JobHistory-row{
    display: flex;
    margin-top: 10px;
}
.JobHistory-name{
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    margin-left: 30px;
    margin-top: 10px;
}
.JobHistory-nameLabel{
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    margin-left: 30px;
}

.JobHistory-taskListContainer{
    background: white;
    height: 70vh;
    border-radius: 25px 25px 0px 0px;
    margin-top: 15px;
}

.JobHistory-taskListTitle{
    font-weight: 700;
    font-size: 22px;
    line-height: 42px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #2843A3;
    padding-top: 20px;
    padding-left: 20px;
}

.JobHistory_Timeline{
    padding-top: "20px !important";
    padding-left: "20px !important";
    overflow: auto;
    height: 55%;
    width: 450px;
}

.JobHistory_Timeline::-webkit-scrollbar { 
    display: none;
}