#popup-container{
    height: 197px / 3;
    width: 246px;
    background-color: white;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
}

#popup-container img{
    
    margin-right: 10px;
}

.equipment-Btn{
    cursor: pointer;
    background-color: white;
    color:#007AFF;
    z-index: 998;
    position: absolute;
    top: 10vh;
    left: 111vh;
    height: 3vh;
    padding: 1%;
    border: 5px;
    border-radius: 30px;
    box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
}

.equipment-Btn:hover{
    background-color: #E3F2FD;
    color:#a8ccee;
}

.jobsBoard-Btn{
    cursor: pointer;
    z-index: 998;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;
    position: absolute;
    width: 195px;
    height: 34px;
    left: 815px;
    top: 104px;
    background: #2196F3;
    color:white;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.jobsBoard-Btn:hover{
    background-color: #1778c7;
    color:#a8ccee;
}