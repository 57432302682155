h2 {
   font-family: inter, sans-serif;
   color: #333333;
   font-weight: normal !important;
}

.MuiTableCell-root {
   border-right: hidden !important;
   border-left: hidden !important;
   color: #666 !important;
   /* padding: 13px 25px 13px 25px !important; */
}

.MuiTablePagination-caption {
   color: #666 !important;
}

.MuiSelect-select {
   color: #666 !important;  
}

.MUIDataTableHeadCell-sortActive-82 {
   color: #666 !important;
}


.MUIDataTableHeadCell-sortActive-157 {
   color: #666 !important;
}

.MUIDataTableHeadCell-sortAction-315 {
   color: #666 !important;
}


.MuiPaper-root {
   border: 1px solid #E5E5E5;
   box-sizing: border-box;
   border-radius: 4px;
   box-shadow: none !important;
}

.MUIDataTable-responsiveScrollMaxHeight-29 {
   margin-top: 15px;
}

.MuiAutocomplete-inputRoot{
   background-color: #f5f5f5 !important;
}

.MuiOutlinedInput-root {
   position: relative;
   border-radius: 4px;
}

.card{
   display: flex;
   height: 105px;
   justify-content: space-between;
   padding-top: 14px;
   padding-bottom: 60px;
}

.user-name{
   font-family: inter, sans-serif;
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 30px;
   letter-spacing: 0.15px;
}

.user-id{
   color: #666 !important;
   font-family: inter, sans-serif;
   font-style: normal;
   font-weight: normal;
   font-size: 17px;
   line-height: 21px;
   letter-spacing: 0.25px;
}

.contact-level{
 display: flex;
 justify-content: space-between !important;
 padding-top: 14px;
}

.dates{
   display: flex;
   justify-content: flex-end;
}

.defaultDirect{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   color:rgba(0, 0, 0, 0.24);
   width: 100px; 
   height: 100px; 
   background: #FFFFFF;
   border: 2px solid #FCE7E7;
   border-radius: 4px ;
   font-family: inter, sans-serif;
   font-style: normal;
   font-weight: bold;
   font-size: 8px;
   line-height: 15px;
   outline: #FCE7E7;
}

.noSelect-red{
   width: 35px;
   height: 25px;
   background-image: url(./icons/noselect-red.png);
   background-repeat: no-repeat;
   background-position: center;
   padding-bottom: 10px;
}

.direct{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100px; 
   height: 100px; 
   border-radius: 4px ;
   border: 2px solid  #FC5D5D;
   font-family: inter, sans-serif;
   font-style: normal;
   font-weight: bold;
   font-size: 8px;
   color: #FFFFFF;
   background: #FC5D5D;
   outline: #FC5D5D;
}

.select-red{
   width: 35px;
   height: 25px;
   background-image: url(./icons/select-red.png);
   background-repeat: no-repeat;
   background-position: center;
   padding-bottom: 10px;
}

.direct-space{
   padding-left: 210px;
   padding-right: 20px;
}

.defaultSecondary {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   color:rgba(0, 0, 0, 0.24);
   width: 100px; 
   height: 100px; 
   background: #FFFFFF;
   border: 2px solid #FFEDBA;
   box-sizing: border-box;
   border-radius: 4px;
   font-family: inter, sans-serif;
   font-style: normal;
   font-weight: bold;
   font-size: 8px;
   line-height: 15px;
   outline: #FFEDBA;
}

.noSelect-yellow{
   width: 35px;
   height: 25px;
   background-image: url(./icons/noselect-yellow.png);
   background-repeat: no-repeat;
   background-position: center;
   padding-bottom: 10px;
}

.secondary{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100px; 
   height: 100px; 
   box-sizing: border-box;
   border: 2px solid #F7D05E;
   border-radius: 4px;
   font-family: inter, sans-serif;
   font-style: normal;
   font-weight: bold;
   font-size: 8px;
   line-height: 15px;
   color: #FFFFFF;
   background: #F7D05E;
   outline:#F7D05E;
}

.select-yellow{
   width: 35px;
   height: 25px;
   background-image: url(./icons/select-yellow.png);
   background-repeat: no-repeat;
   background-position: center;
   padding-bottom: 10px;
}

.secondary-space{
   padding-right: 20px;
}

.defaultTertiary {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   color:rgba(0, 0, 0, 0.24);
   width: 100px; 
   height: 100px; 
   background: #FFFFFF;
   border: 2px solid #EBEBEB;
   box-sizing: border-box;
   border-radius: 4px;
   font-family: inter, sans-serif;
   font-style: normal;
   font-weight: bold;
   font-size: 8px;
   line-height: 15px;
   outline:#EBEBEB;
}

.noSelect-gray{
   width: 35px;
   height: 25px;
   background-image: url(./icons/noselect-gray.png);
   background-repeat: no-repeat;
   background-position: center;
   padding-bottom: 10px;
}

.tertiary{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100px; 
   height: 100px; 
   box-sizing: border-box;
   border: 2px solid #A9A9A9;
   border-radius: 4px;
   font-family: inter, sans-serif;
   font-style: normal;
   font-weight: bold;
   font-size: 8px;
   line-height: 15px;
   color: #FFFFFF;
   background: #A9A9A9;
   outline:#A9A9A9;
}

.select-gray{
   width: 35px;
   height: 25px;
   background-image: url(./icons/select-gray.png);
   background-repeat: no-repeat;
   background-position: center;
   padding-bottom: 10px;
}

.back-btn{
   color: #9E9E9E;
   border: none;
   background: none;
}

.div-btn{
   padding-top: 28px;
   padding-bottom: 31px;
}

.back-btn:active{
   border: none !important;
}