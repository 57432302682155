.jobsCalendar-deleteJobCardContainer{
    position: absolute;
    top: 30px;
    left: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 24px rgb(0 0 0 / 12%);
    border-radius: 4px;
    height: 30px;
    width: 40px;
    text-align: center;
}

.jobsCalendar-deleteJobCardContainerLast{
    position: absolute;
    top: -20px;
    left: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 24px rgb(0 0 0 / 12%);
    border-radius: 4px;
    height: 30px;
    width: 40px;
    text-align: center;
}

.jobsCalendar-iconButtonContainer{
    display: flex;
    padding-top: 13px;
}

.jobsCalendar-iconButtonEnabled{
    padding-top: 5px;
    padding-left: 5px;
}

.jobsCalendar-iconButtonEnabled:hover{
    cursor: pointer;
}

.jobsCalendar-groupTitleDisabled{
    width: 103px;
    height: 22px;
    margin-top: 5%;
    margin-left: 8%;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.65);
}

.jobsCalendar-groupTitleDisabledSmall{
    width: 103px;
    height: 22px;
    margin-top: 5%;
    margin-left: 8%;
    font-weight: 500;
    font-size: 10px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.65);
}

.jobsCalendar-iconButton{
    padding-top: 5px;
    position: absolute;
    left: 72%;
    padding-left: 5px;
}

.jobsCalendar-BottomBtnContainer{
    display: flex;
    margin-top: 20px;
}

.jobsCalendar-BottomBtnDiv{
    display: inline;
}

.jobsCalendar-BottomCancelBtnDiv{
    display: inline;
    margin-left: 20px;
}

.jobsCalendar-TimelineContainer{
    margin-top: 50px;
}

.jobsCalendar-SlotIcon{
    height: 20px;
    width: 20px;
    margin-left: 2px;
    display: table-cell;
}

.jobsCalendar-RemoveJobLabel{
    margin-left: 30px;
    color: rgba(0, 0, 0, 0.65);
    letter-spacing: 0.1px;
    font-weight: 500;
    font-size: 12px;
}

.jobsCalendar-OrLbl{
    margin-left: 15px;
    margin-top: 5px;
    font-style: italic;
}

.jobsCalendar-TodayMarker{
    background-color: #2843A3 !important;
}