.superAdmin{
  display: flex;
  justify-content: center;
  height: 100vh;
  min-height: 820px;
  background: #f5f5f5;
}

.logo{
  position: absolute;
  box-sizing: border-box;
  left: 56px !important;
  Top: 30px;
}

.header{
  position: absolute;
  width: 215px;
  height: 69px;
  Top: 12px;
  left: 135px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 39px;
  line-height: 53px;
  display: flex;
  align-items: center;
  color: #2843A3;
}

.super-login{
  display: flex;
  justify-content: center;
  width: 414px;
  height: 775px;
  position: absolute;
  top: 30px;
  background: #FFFFFF;
  border: 1px solid #EFF4FA;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.admin-panel{
  position: absolute;
  width: 296px;
  left: 58px;
  top: 120px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 27px;
  text-align: center;
  color: #2843A3;  
}

.mail{
  position: absolute;
  left: 59px;
  top:207px;
  width: 47px;
  height: 23px;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;    
  color: #6F6F6F !important;
}

.input-email{
  position: absolute;
  left: 59px;
  width: 296px;
  height: 45px;
  top: 254px;
  background: #F9FAFF;
  border: 0.5px solid #979797;
  box-sizing: border-box;
  border-radius: 10px;
}

.pass{
  position: absolute;
  left: 59px;
  top:317px;
  width: 47px;
  height: 23px;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;    
  color: #6F6F6F !important;
}

.input-password{
  position: absolute;
  left: 59px;
  width: 296px;
  height: 45px; 
  top: 334px;   
  background: #F9FAFF;
  border: 0.5px solid #979797;
  box-sizing: border-box;
  border-radius: 10px;
}

.login-btn{
  background: #CFCFCF !important;
  border: 0.5px solid #979797 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  width: 296px;
  height: 45px;
  font-style: normal !important;
  font-weight: bold;
  font-size: 15px !important;
  line-height: 18px !important;
  align-items: center;
  color: #FFFFFF;
}

.or{
  position: absolute;
  left: 200px;
  top: 500px;
  color: #6F6F6F;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.04em;
}

.campus-btn{
  position: absolute;
  top: 610px;
  left: 70px;
  background: #2843A3 !important;
  border: 0.5px solid #979797 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  position: absolute;
  width: 296px;
  height: 45px;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;
  align-items: center;
  color: #FFFFFF !important;
}

.inputEmailErr{
  width: 296px;
  height: 45px;
  background: #F9FAFF;
  border: 0.2px solid red;
  box-sizing: border-box;
  border-radius: 10px;
  }

.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiInput-underline:after{
  border-bottom: none !important;
}

.MuiFormControl-root:focus{
  outline: none !important;
  border: none !important;
}

.helpEmail{
  color:red !important;
}

.inputPasswordErr{
  width: 296px;
  height: 45px;
  background: #F9FAFF;
  border: 0.2px solid red;
  box-sizing: border-box;
  border-radius: 10px;
}

.helpPass{
  color:red !important;
}