.Filters_Container{
    display: flex;
    width: 170px;
    margin-left: 25px;
    border-radius: 6px;
    height: 20px;
    padding: 10px;
}

.Filters_Text{
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 3px;
    width: 110px;
    font-size: 13px;
}

.Filters_Checkbox{
    margin-top: -10px;
}