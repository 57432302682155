.App{
  height: 100vh;
}
.App-header {
    position: absolute;
    width: 215px;
    height: 69px;
    margin-top: 9%;
    margin-left: 16%;
    font-style: normal;
    font-weight: bold;
    font-size: 260%;
    line-height: 20%;
    display: flex;
    align-items: center;
    color: #2843A3;
    box-sizing: border-box;
  }
.m-logo{
  position: absolute;
  width: 78.25px;
  height: 102.43px;
  margin-left: 11%;
  margin-top: 8.5%;
  box-sizing: border-box;
}
.p-vector{
  position: absolute;
  width:'15%';
  height: '18%';
  left: 57px;
  top: 174px;
}
.phone-vector{
  position: absolute;
  left: 470px;
  top: 189px;
  width: 88.25px;
  height: 128.25px;
}
.b-logo{
  margin-top: -15vh;
  width: 80vh;
  height: 110vh;
  float: left;
  margin-left: 45vh; 
  opacity:0.1;
}

.left-splash{
  background: linear-gradient(270.45deg, #17519D 0.44%, #337BD8 99.7%);
  width: 60%;
  height: 100vh;
  float: left;
}

.welcome-to-novatrack  {
position: absolute;
width: 437px;
height: 114px; 
left: 5vh;
top: 60vh;
font-style: normal;
font-weight: bold;
font-size: 58px;
line-height: 102.6%;
display: flex;
align-items: center;
color: #FFFFFF;
}

.fancy-copy-here-ver {
  position: absolute;
  width: 437px;
  height: 38px;
  left: 5vh;
  top: 79vh;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.07em;
  color: #FFFFFF;
}

.bacon-ipsum-dolor-am {	
  color: rgba(255,255,255,0.8);	
  position: absolute;
  width: 437px;
  height: 38px;
  left: 5vh;
  top: 85vh;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.version{
  color: rgba(255,255,255,0.1);	
  position: absolute;
  width: 437px;
  height: 38px;
  left: 53%;
  top: 92%;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.login-form{
    float:left;
    width: 40%;
    height: 100vh;
}

.login-header{
    font-size: 24px;
    font-weight: 500;
	  color: rgba(0,0,0,0.77);
    width: 222px;
    line-height: 32px;
}

input{
    width: 329px;
    margin-bottom: 23px;
}

.right-mid-margin{
    margin-right: 16px;
}

button{
  color:#3D5AFE
}

.superBtn{
  background-image: url(./hospital-icons/superAd.png);
  background-repeat: no-repeat;
  margin-top: 40px;
  text-decoration: none;
  width: 20px;
  height: 20px;
}

@media only screen and (max-width: 1440px) {
  .App-header {
      position: absolute;
      width: 215px;
      height: 69px;
      margin-top: 9%;
      margin-left: 16%;
      font-style: normal;
      font-weight: bold;
      font-size: 240%;
      line-height: 20%;
      display: flex;
      align-items: center;
      color: #2843A3;
      box-sizing: border-box;
    }
  .m-logo{
    position: absolute;
    width: 68.25px;
    height: 92.43px;
    margin-left: 10%;
    margin-top: 8.5%;
    box-sizing: border-box;
  }
  .p-vector{
    position: absolute;
    width: 400px;
    height: 300px;
    left: 47px;
    top: 154px;
  }
  .phone-vector{
    position: absolute;
    left: 400px;
    top: 147px;
    width: 88.25px;
    height: 128.25px;
  }
  .b-logo{
    margin-top: -15vh;
    width: 80vh;
    height: 110vh;
    float: left;
    margin-left: 37vh; 
    opacity:0.1;
  }
  
  .left-splash{
    background: linear-gradient(270.45deg, #17519D 0.44%, #337BD8 99.7%);
    width: 60%;
    height: 100vh;
    float: left;
  }
  
  .welcome-to-novatrack  {
  position: absolute;
  width: 437px;
  height: 114px; 
  left: 5vh;
  top: 58vh;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 102.6%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  }
  
  .fancy-copy-here-ver {
    position: absolute;
    width: 437px;
    height: 38px;
    left: 5vh;
    top: 78vh;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.07em;
    color: #FFFFFF;
  }
  
  .bacon-ipsum-dolor-am {	
    color: rgba(255,255,255,0.8);	
    position: absolute;
    width: 437px;
    height: 38px;
    left: 5vh;
    top: 85vh;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
  .version{
    color: rgba(255,255,255,0.2);	
    position: absolute;
    width: 437px;
    height: 38px;
    left: 52%;
    top: 90%;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
  .login-form{
      float:left;
      width: 40%;
      height: 100vh;
  }
}
