/*
@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto/Roboto-Regular.ttf');
}
*/

.superAdmin{
  display: flex;
  justify-content: center;
  height: 100vh;
  background: #f5f5f5;
}

.logo{
  left: 20px;
}

.header{
  position: absolute;
  width: 215px;
  height: 69px;
  left: 74px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 39px;
  line-height: 53px;
  display: flex;
  align-items: center;
  color: #2843A3;
}

.super-select{
  display: flex;
  justify-content: center;
  Width: 414px;
  position: absolute;
  top: 40px;
  bottom: 40px;
  background: #FFFFFF;
  border: 1px solid #EFF4FA;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.welcome{
  position: absolute;
  height: 63px;
  top: 230px;   
  font-style: normal;
  font-weight: 300;
  font-size: 29px;
  line-height: 35px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #6F6F6F;
}

.select-hospital{
  position: absolute;
  width: 296px;
  height: 19px;
  left: 60px;
  top: 120px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 27px;
  text-align: center;
  color: #2843A3;  
  }
  
.btns{
  position: absolute;
  top: 250px;
  left: 60px;
}

.button1{
  background: #F8F8F8 !important;
  border: 0.5px solid #979797 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  position: absolute;
  width: 296px;
  height: 57px;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 18px !important;
  align-items: center;
  color: #000000;
  text-transform: capitalize !important;
}

.button2{
  background: #F8F8F8 !important;
  border: 0.5px solid #979797 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  position: absolute;
  width: 296px;
  height: 57px;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 18px !important;
  align-items: left;
  color: #000000;
  text-transform: capitalize !important;
  margin-top: 20px !important;
  padding-left: 61px !important;
  margin-top: 20px !important;
}

.button3{
  background: #F8F8F8 !important;
  border: 0.5px solid #979797 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  position: absolute;
  width: 296px;
  height: 57px;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 18px !important;
  align-items: left;
  color: #000000;
  text-transform: capitalize !important;
  margin-top: 20px !important;
  padding-left: 61px !important;
  margin-top: 20px !important;
}

.button4{
  background: #F8F8F8 !important;
  border: 0.5px solid #979797 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  position: absolute;
  width: 296px;
  height: 57px;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 18px !important;
  align-items: center;
  color: #000000;
  text-transform: capitalize !important;
  margin-top: 20px !important;
}

.button{
  background: #F8F8F8 !important;
  border: 0.5px solid #979797 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  position: absolute;
  width: 296px;
  height: 57px;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 18px !important;
  text-transform: capitalize !important;
  color: #000000;
  margin-top: 20px !important;
}

  .hosp-icon{
    background-image: url(./hospital-icons/hosp-btn.png) !important;
    background-repeat: no-repeat !important;
    background-position: left 7px top 8px !important;
  }

.super-icon{
  background-image: url(./hospital-icons/super-btn.png) !important;
  background-repeat: no-repeat !important;
  background-position: left 7px top 8px !important;
}