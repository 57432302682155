.chat-window{
    background-color: white;
    width: 264px;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.2);
    align-self: end;
    direction: ltr;
    border-radius: 10px 10px 0px 0px;
}

.chat-open{
    height: 448px!important;
    overflow-y: auto;
}

.chat-closed{
    display: none;
}

.chat-header{
    height: 48px;
    background-color: #2843A3;
    color: white;
    text-align: left;
    padding-top: 1px;
    padding-left: 16px;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.chat-normal{
    height: 48px;
    background-color: #2843A3;
    color: white;
    font-weight: bold;
    text-align: left;
    border-radius: 10px 10px 0px 0px;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    display: grid;
    grid-template-columns: 190px 40px;
    grid-column-gap: 10px;
    padding-left: 16px;
}

.close-action{
    margin-left:10px;
}

.chat-action{
    display: flex;
    cursor: pointer;
    padding-top: 15px;
}

.message-view{
    background-color: white;
    height: 392px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    overflow-y: auto;
    padding-top: 10px;
}

.message-box{
    background-color:white;
    height:32px;
    padding:8px;
}

.search-box{
    background-color:white;
    height:32px;
    padding:8px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.search-input{
    background-color: rgba(0,0,0,0.04);
    width: 246px;
    height: 100%;
    float:left;
}

.message-input{
    background-color: rgba(0,0,0,0.04);
    width: 200px;
    height: 115%;
    float:left;
}

.message-send-button{
    background-color: rgba(0,0,0,0.0);
    width: 30px;
    height: 91%;
    float:left;
    margin-left: 10px;
    padding-left:6.8px;
    padding-top: 7px;
    cursor: pointer;
}

.message-box .search-wrapper{
    width:190px!important;
    float: left;
    height: 36px;
    background-color: #F4F4FF;
    border-radius: 10px;
}

.message-bubble{
    min-width: 40px;
    max-width: 200px;
    border-radius: 10px !important;
    padding:10px;
    overflow-wrap: break-word;
    margin-bottom: 8px;
}

.message-sent{
    margin-left: 30px;
    background-color: #2843A3;
    color:white;
    position: relative;
    border-radius: 25px;
}

.message-sent:before{
    content: "";
    position: absolute;
    top: 100%;
    left: 180px;
    width: 0;
    border-top: 7px solid #2843A3;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
}

.message-got{
    margin-left: 5px;
    color:black;
    background-color: #F4F4FF;
    position: relative;
    border-radius: 25px;
}

.message-got:before{
    content: "";
    position: absolute;
    top: 100%;
    left: 20px;
    width: 0;
    border-top: 7px solid #F4F4FF;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
}

.time-sent{
    font-size: 11px;
    margin-bottom: 8px;
    text-align: right;
    margin-right: 8px;
    color: gray;    
}
.typing{
    font-size: 11px;
    margin-bottom: 8px;
    text-align: right;
    margin-right: 8px;
    color: gray;
    float: left;
    margin-left: 10px;
}

.time-got{
    font-size: 11px;
    margin-bottom: 8px;
    text-align: left;
    margin-left: 10px;
    color:gray;
}

.empty .message-bubble{
    height: 20px;
}

.no-messages{
    padding-top: 30px;
    width: 100%;
    text-align: center;
    color:gray;
}

.unconfirmed{
    opacity: .5;
}

.empty .message-got{
    background: linear-gradient(241deg, #f8f8f8, #bebebe);
    background-size: 400% 400%;

    -webkit-animation: empty-contacts 3s ease infinite;
    -moz-animation: empty-contacts 3s ease infinite;
    -o-animation: empty-contacts 3s ease infinite;
    animation: empty-contacts 3s ease infinite;
}

.empty .message-sent{
    background: linear-gradient(241deg, rgb(170, 188, 255), rgb(117, 147, 255));
    background-size: 400% 400%;

    -webkit-animation: empty-contacts 3s ease infinite;
    -moz-animation: empty-contacts 3s ease infinite;
    -o-animation: empty-contacts 3s ease infinite;
    animation: empty-contacts 3s ease infinite;
}


@-webkit-keyframes empty-contacts {
    0%{background-position:0% 73%}
    50%{background-position:100% 28%}
    100%{background-position:0% 73%}
}
@-moz-keyframes empty-contacts {
    0%{background-position:0% 73%}
    50%{background-position:100% 28%}
    100%{background-position:0% 73%}
}
@-o-keyframes empty-contacts {
    0%{background-position:0% 73%}
    50%{background-position:100% 28%}
    100%{background-position:0% 73%}
}
@keyframes empty-contacts { 
    0%{background-position:0% 73%}
    50%{background-position:100% 28%}
    100%{background-position:0% 73%}
}

