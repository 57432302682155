.reset-form{
    text-align: center;
    width: 100%;
    margin-top: 15%;
}

.reset-header {
    font-size: 24px;
    font-weight: 500;
	color: rgba(0,0,0,0.77);
    line-height: 32px;
}

.successParagraph{
    font-size: 20px;
    color: green
}

.errorParagraph{
    font-size: 20px;
    color: red;
}