.TrackInfo_Container{
    display: flex;
    border-radius: 6px;
    height: 20px;
    padding: 5px;
    padding-left: 10px;
}

.TrackInfo_Text{
    margin-right: 10px;
    padding-top: 3px;
    font-size: 13px;
}

.TrackInfo_TextTitle{
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 3px;
    font-size: 13px;
    font-weight: 600;
}

.TrackInfo_ContainerSubheader{
    display: flex;
    border-radius: 6px;
    height: 20px;
    padding: 10px;
    font-weight: 600;
    font-size: 18px;
}

.TrackInfo_ContainerSubheader:hover{
    background-color: #dfdbdbc0;
    cursor: pointer
}

.TrackInfo_TextSubheader{
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 3px;
    font-size: 15px;
    color: #2843A3;
}