.acl-container{
    padding: 10px;
    padding-left: 70px;
    display: flex;
    width:90%;
    height: 90%;
}
.acl-header{
    display:flex;
    width:100%;
    margin-top:5%;
}
.acl-headerIcon{
    fill:#2843A3;
    font-size:50px;
    opacity:0.7;
    margin-left:5%;
}
.acl-headerTitle{
    color:#2843A3;
    margin-left:1%;
}
.team-date{
    margin-left: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.24);
    display:inline-block;
}
.team-data{
    margin-left: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.6);
    display:inline-block;
}
.section{
    max-width: 300px;
    padding: 8px;
}