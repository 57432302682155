.card{
  display: flex;
  height: auto;
  padding-top: 14px;
  padding-bottom: 0px;
  justify-content: normal;
}

.user-name{
  font-family: inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
}

.arrow-icon{
  color:#2843A3;
  height: auto;
  padding: 0 15px 0 15px;
}