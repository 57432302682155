.rct-header-root {
    background-color: white !important;
}

.rct-scroll{
    /* overflow-x: hidden !important;
    overflow-y: unset !important; */
}

.rct-hl-odd{
    background-color: white !important;
}

.rct-sidebar-row{
    background-color:#F1F1F1 !important;
}

.rct-dateHeader{
    background-color: transparent !important;
    justify-content:  unset !important;
    border-left: none !important;
}

.rct-calendar-header{
    border: none !important;
}

.MuiPaper-root .MuiStepper-root .MuiStepper-vertical .MuiPaper-elevation0{
    border: none !important;
}

.disabledRow{
    background:rgba(54, 54, 54, 0.04)  !important;
}

.actionsRow{
    background-color: #F4D7D6 !important;
}

.templatesRow{
    background-color: lightblue !important;
}

.JobsBoard_TitleContainer{
    display: flex;
}

.JobsBoard_TitleIcon{
    position: absolute;
    left: 100px;
    top: 109px;
}

.JobsBoard_Title{
    position: absolute;
    color: #2843A3;
    font-weight: 500;
    font-size: 26px;
    left: 140px;
    top: 105px;
}

.JobsBoard_JobInfoViewTitleIcon{
    position: absolute;
    left: 100px;
    top: 110px;
}

.JobsBoard_Path1{
    margin-top: 5px;
    width: 158px;
    height: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #9E9E9E;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.JobsBoard_Path2{
    margin-top: 5px;
    width: 88px;
    height: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #9E9E9E;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.JobsBoard_EditLabel{
    font-style: italic;
    position: absolute;
}

.JobsBoard_JobInfoViewContainer{
    width: 90%;
    position: absolute;
    height: 20%;
}