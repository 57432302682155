.newPass-form{
    float:left;
    width: 35%;
    margin-top: 278px;
    padding-left: 25px;
}

.newPass-header{
    font-size: 24px;
    font-weight: 500;
	  color: rgba(0,0,0,0.77);
    width: 222px;
    line-height: 32px;
}