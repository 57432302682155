.chat-window-container{
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 120px;
    background-color: transparent;
    float: right;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: repeat(5, 264px);
    flex-direction: row-reverse;
    justify-content: flex-end;
    direction: rtl;
    display:'none';
}

.contact-container{
    display: grid;
    grid-template-columns: 35px 192px 7px;
    grid-auto-rows: 40px;
    grid-column-gap: 5px;
}

.contact-container div{
    align-self: center;
    justify-self: center;
}

.empty-contacts div{
    background: linear-gradient(241deg, #ececec, #afafaf);
    background-size: 400% 400%;

    -webkit-animation: empty-contacts 3s ease infinite;
    -moz-animation: empty-contacts 3s ease infinite;
    -o-animation: empty-contacts 3s ease infinite;
    animation: empty-contacts 3s ease infinite;
}

@-webkit-keyframes empty-contacts {
    0%{background-position:0% 73%}
    50%{background-position:100% 28%}
    100%{background-position:0% 73%}
}
@-moz-keyframes empty-contacts {
    0%{background-position:0% 73%}
    50%{background-position:100% 28%}
    100%{background-position:0% 73%}
}
@-o-keyframes empty-contacts {
    0%{background-position:0% 73%}
    50%{background-position:100% 28%}
    100%{background-position:0% 73%}
}
@keyframes empty-contacts {
    0%{background-position:0% 73%}
    50%{background-position:100% 28%}
    100%{background-position:0% 73%}
}

