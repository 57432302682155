.add-button{
    height: 7rem;
    width: 0;
    position: 'sticky';
    margin-left: '95%';
    bottom:'20vh';
}

.dvlblnDrop {
    align-items: center; 
    display: flex;
    padding: 10px 0px 10px 0px;
}
label{
   margin-right: 30px;
 
}
.class-composition{
    padding: 20px 8px 20px 8px;
}
.mandatory{
    color: red;
}
.user-status.online{
    color: #3FA33E;
    cursor:pointer;
}
.user-status.idle{
    color: #3FA33E;
    cursor:pointer;
}
.user-status.inactive{
    color:orange;
    cursor:pointer;
}
.user-status.issues{
    color: lightgray;
}
.user-status.text{
    color: #2843A3;
}
.d-vector{
    position: absolute;
    opacity: 0.1;
    margin-top: 2px;
    width:100%;
    height:13%;
}
.statusContainer{
    margin: 0 2px;
    height: 9px;
    width: 9px;
    border-radius: 4.5px;
    position: relative;
    top: 5px;
}
.statusMainDiv{
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1750px) {
    .add-button{
        height: 9rem;
    }
}