.DeleteRowMessage_RemoveLabelContainer{
    position: absolute;
    z-index: 1000;
    left: 50%;
    color:#C83532;
    width: 250px;
    font-size: 17px;
}

.DeleteRowMessage_RemoveLabel{
    position: absolute;
    left: 30px;
    top: 67%;
    width: 200px;
    font-style: italic;
}