.nav-bar{
    top: 0 !important;
    left: 0 !important;
    background-color: #FFF !important;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24), 0 0 8px 0 rgba(0,0,0,0.12), 0 8px 8px 0 rgba(0,0,0,0.24);
    position: fixed !important;
    left:0;
    top: 0;
    width: 100%;
    z-index: 1001 !important;
}

.toolbar-title{
    margin-left: -0px !important;
    color:#000 !important;
}

.search-bar{
    background-color: rgba(243,242,243,1) !important;
}

.button-tool-bar{
    color:rgba(0,0,0,0.5) !important;
}

.advance-tools{
    position: absolute;
    bottom: 0px;
}